import { dev } from '$app/environment'
import clientConfig from '$lib/clientConfig'
import { initializeApp, type FirebaseApp } from 'firebase/app'
import { connectAuthEmulator, getAuth, type Auth } from 'firebase/auth'
import { getStorage, type FirebaseStorage, connectStorageEmulator } from 'firebase/storage'

const firebaseConfig = {
	apiKey: 'AIzaSyD_-M9HNiPTS-gqohqbouWalNJ4GBCRxe4',
	authDomain: 'appestreker.firebaseapp.com',
	projectId: 'appestreker',
	storageBucket: 'appestreker.appspot.com',
	messagingSenderId: '463349115978',
	appId: '1:463349115978:web:d15dc84f6ac2f008e10ab3',
	measurementId: 'G-6KECTBH9XS'
}

let app: FirebaseApp | undefined
let clientAuth: Auth | undefined
let clientStorage: FirebaseStorage | undefined

const localhost = '127.0.0.1'

const getClientApp = (): FirebaseApp => {
	if (!app) {
		app = initializeApp(firebaseConfig)
	}

	return app
}

export const getClientAuth = (): Auth => {
	const clientApp = getClientApp()

	if (!clientAuth) {
		clientAuth = getAuth(clientApp)

		if (clientConfig.useEmulators) {
			connectAuthEmulator(clientAuth, 'http://' + localhost + ':9099')
		}
	}

	return clientAuth
}

export const getClientStorage = (): FirebaseStorage => {
	const clientApp = getClientApp()

	if (!clientStorage) {
		clientStorage = getStorage(clientApp)

		if (clientConfig.useEmulators) {
			connectStorageEmulator(clientStorage, localhost, 9199)
		}
	}

	return clientStorage
}
